import React from "react"
class DashboardCell extends React.Component {
    constructor(props) {
        super(props)
        this.fn = props.fn
    }
    componentDidMount() {}
    render() {
        return (
            <div className="d-inline-block d-block-md my-1 align-top" style={{ width: this.props.width ?? "auto" }}>
                <div className="pr-1 position-relative w-100">
                    <div className="p-2 border mt-2 border-box">{this.props.value}</div>
                    <div
                        className="position-absolute top-0 left-2 px-1 fs-sm dx-theme-background-color dx-theme-text-color"
                        style={{ marginTop: "-0.5rem" }}
                    >
                        {this.props.title}
                    </div>
                </div>
            </div>
        )
    }
}
export default DashboardCell
