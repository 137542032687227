import React from "react"
import { PostData } from "../../Helper"
import { declare } from "./default/declare"
import { onStart } from "./default/event"
import { renderMain } from "./default/render"
class Default extends React.Component {
    constructor(props) {
        super(props)
        this.state = props.state
        this.fn = props.fn
        declare(this)
    }
    componentDidMount() {
        if (this.state.form === undefined)
            this.setState({
                form: React.lazy(() =>
                    import("./form/" + this.state.info.splitId[1]).catch((ex) => {
                        this.setState({ form: null })
                        onStart(this)
                    })
                )
            })
    }
    render() {
        return (
            <>
                {this.state.error !== undefined ? (
                    <div className="text-danger alt-font-bold" children={this.state.error}></div>
                ) : this.state.warning !== undefined ? (
                    <div className="text-warning alt-font-bold my-1" children={this.state.warning}></div>
                ) : (this.state.config === null || this.state.config === undefined) && this.state.form === null ? (
                    <div className="alt-font-bold">
                        <span className="fa fa-circle-o-notch fa-spin"></span> Please wait...
                    </div>
                ) : (
                    <>{renderMain(this)}</>
                )}
                {this.state.error !== undefined || this.state.warning !== undefined ? (
                    <button className="btn btn-light btn-sm mt-1" onClick={() => onStart(this)}>
                        <span className="fa fa-exchange" /> Retry
                    </button>
                ) : (
                    <></>
                )}
            </>
        )
    }
}
export default Default
