import React from "react"
import DateBox from 'devextreme-react/date-box';
import DropDownBox from 'devextreme-react/drop-down-box';
import { PostData } from "../../../Helper";
import DataGrid, {
    Selection, Paging, FilterRow, Scrolling,Column,MasterDetail,Editing
  } from 'devextreme-react/data-grid';
import axios from "axios";
import { Button } from 'devextreme-react/button';
//import GroupMasterDetail from './detailopenpo';
import CustomStore from "devextreme/data/custom_store";
import { CheckBox } from "devextreme-react";

const date = new Date(2018, 9, 16, 15, 8, 12);


let dg1;
export class OpenPurchaseOrderApproval extends React.Component {
    constructor(props) {
        super(props)
        //this.state = props.state
        this.state = {
            ...props,
            dateFrom: '2023-02-01',
            dateTo: '2023-02-01',
            kdcoa: "",
            loadcoa: [],
            gridBoxValue: [3],
            loadgridheader:[],
            
           
        }
        this.fn = props.fn
        this.now = new Date();
        //console.log(window.url)
        //console.log(props);
        //console.log("halo")
        this.getRef = this.getRef.bind(this);
        this.onChangeDateFrom = this.onChangeDateFrom.bind(this);
        this.onChangeDateTo = this.onChangeDateTo.bind(this);
        
    
        
    }

    onRefresh = (p) => {
        console.log(p);
        this.dataGrid.instance.refresh();
    }


    getRef(ref) {
        this.dataGrid = ref;
        window.dataGrid = this.dataGrid;
      }

      refreshDataGrid() {
        //console.log("refresh ini");
        //console.log(this.dataGrid);
        this.dataGrid.instance.refresh();
      }

    onChangeDateFrom(e) {
        //console.log(e.value);
        this.setState({
          dateFrom: e.value,
        });
      }

      onChangeDateTo(e) {
        //console.log(e.value);
        this.setState({
          dateTo: e.value,
        });
      }

    
    componentDidMount() {
        
        PostData({url:"open/openpoapprovalfilter/setdate",success:(res)=>{
            
            this.setState({ dateFrom:res["tgldari"],dateTo:res["tglsampai"] });

        }})
        
        let headersku = { "Access-Control-Allow-Origin": "*", Authorization: "Bearer "+localStorage.getItem(window.token), "data-key": window.token, "data-dir": process.env.REACT_APP_DIR }
        
        /*
        PostData({url:"open/openpofilter/getfilter",data:{fromdate:'2023-02-01',todate:'2023-02-20'},showLoading:true,success:(res)=>{
            this.setState({loadgridheader:res['header']}) ;
        }})*/

        this.setState({
			loadgridheader: new CustomStore({
				key: ["id"],
				loadMode: "raw",
				load: () => {
                    
                    // PostData({url:"open/openpofilter/getfilter",data:{fromdate:'2023-02-01',todate:'2023-02-20'},showLoading:true,success:(res)=>{
                    //     console.log(res['header']);
                    //     return res['header'];
                        
                    // }})
                    return axios.post(window.url + "open/openpoapprovalfilter/getfilter", {fromdate:this.state.dateFrom,todate:this.state.dateTo},
                    { headers: headersku })
                    
                    .then((response) => {
                      this.setState ({
                        //dateFrom:response.data["tgldari"],dateTo:response.data["tglsampai"]
                      })
                      return response.data["header"]});
				},update: (key,values) => {
         //console.log("asdd");
                    return axios
                        .post(window.url + "open/openpoapprovalfilter/updatedetail", {id: key,data:values,fromdate:this.state.dateFrom,todate:this.state.dateTo},
                        { headers: headersku })
                        .then((response) => {
                        
                          return response.data["header"]});
                        
                }
			})
		})

    }
    
    onFilter = (e) => {
        console.log("tekan aqku");
       /* this.setState({
			loadgridheader: new CustomStore({
				key: "ID",
				loadMode: "raw",
				load: () => {
         */           
          this.refreshDataGrid();      
				}
			//})
		//})
        
       
      //}

    //onRefresh = () => {}




    cellRender = (data) => {
        if(data.value+"" === "0" || data.value+"" === "null" || data.value === false){
          return <CheckBox defaultValue={false} disabled={true}/>;
        }
        return <CheckBox defaultValue={true} disabled={true}/>;
      }
      
      cellRenderupdate = (cell) => {
        if(cell.value+"" === "0") cell.value = false;
        let onValueChanged = this.onValueChanged.bind(this, cell);
        return <CheckBox defaultValue={cell.value} onValueChanged={onValueChanged}/>;
      }
  
      onValueChanged(cell, e) {
        cell.setValue(e.value);
      }


    render() {
        return (
            <div className="row">
                <div className="col-md-12">Filter</div>
                <div className="col-md-12">   
                    <div className="dx-field">
                        <div className="dx-field-label">From:</div>
                        <div className="dx-field-value">
                            <DateBox
                            defaultValue={date}
                            //placeholder="2021-10-16"
                            showClearButton={true}
                            useMaskBehavior={true}
                            type="date"
                            displayFormat="yyyy-MM-dd"
                            onValueChanged={this.onChangeDateFrom}
                            value={this.state.dateFrom}
                            />
                        </div>
                    </div>


                    <div className="dx-field">
                        <div className="dx-field-label">To:</div>
                        <div className="dx-field-value">
                            <DateBox
                            defaultValue={date}
                            //placeholder="2021-10-16"
                            showClearButton={true}
                            useMaskBehavior={true}
                            type="date"
                            displayFormat="yyyy-MM-dd"
                            onValueChanged={this.onChangeDateTo}
                            value={this.state.dateTo}
                            />
                        </div>
                    </div>
                </div>

                
                <div className="col-md-12">
                    
                    
                        <Button
                        width={120}
                        text="Filter"
                        type="normal"
                        stylingMode="contained"
                        onClick={this.onFilter}
                        />
                    
                    
                </div>

                
                <div className="row">
                <div className="col-md-12">
                
                <DataGrid
                    dataSource={this.state.loadgridheader}
                     ref={this.getRef}
                    onInitialized={(e) => this.setState({ grid: e.component })}
                    showBorders={true}
                    columnAutoWidth={true}
                    wordWrapEnabled={true}
                    keyExpr={["id","number"]}
                >

                <Editing mode="batch" allowUpdating={true} useIcons={true} />
               
                <Column dataField="status"  caption="Approve" dataType="boolean"  cellRender={this.cellRender}
                editCellRender={this.cellRenderupdate}/>
                <Column dataField="id" caption="Kode Transaksi" allowEditing={false} visible={true} groupIndex={0} />
                <Column dataField="supplier_name"  caption="Nama Supplier" allowEditing={false}  visible={true}/>
                <Column dataField="date"  caption="Tanggal" allowEditing={false} />
                    
                </DataGrid>
                </div>
                </div>

                
                    
            </div> 
        )
    }

    
}
export default OpenPurchaseOrderApproval;