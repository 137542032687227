import React from "react"
import { PostData } from "../../../Helper"
import { declare } from "../default/declare"
import { onStart } from "../default/event"
import { renderTable } from "../default/render"
class Warehouse extends React.Component {
    constructor(props) {
        super(props)
        this.$ = props.$
        this.fn = this.$.fn
        console.log(this)
    }
    componentDidMount() {
        this.$.waiting = true
    }
    render() {
        return <>HALO</>
    }
}
export default Warehouse
