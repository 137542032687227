import { ScrollView, Sortable } from "devextreme-react"
import React from "react"
import DashboardCard from "./card"
class DashboardList extends React.Component {
    constructor(props) {
        super(props)
        this.fn = this.props.fn
    }
    componentDidMount() {}
    render() {
        return (
            <div className="list">
                <div className="list-title dx-theme-text-color">{this.props.title}</div>
                <ScrollView className="scrollable-list" direction="vertical" showScrollbar="always">
                    <Sortable
                        className="sortable-cards"
                        group="cardsGroup"
                        data={this.props.index}
                        onDragStart={this.props.onTaskDragStart}
                        onReorder={this.props.onTaskDrop}
                        onAdd={this.props.onTaskDrop}
                    >
                        {this.props.tasks.map((task) => (
                            <DashboardCard
                                task={task}
                                customer={this.props.customer}
                                fn={this.fn}
                                updateState={this.props.updateState}
                                kanban={this.props.kanban}
                            />
                        ))}
                    </Sortable>
                </ScrollView>
            </div>
        )
    }
}
export default DashboardList
