import { DataGrid, Form, Popup, ScrollView, Sortable, TabPanel } from "devextreme-react"
import { Column } from "devextreme-react/data-grid"
import React from "react"
import "./dashboard_msepaper/dashboard.css"
import DashboardHeader from "./dashboard_msepaper/header"
import DashboardList from "./dashboard_msepaper/list"

class Dashboard extends React.Component {
    constructor(props) {
        super(props)
        this.state = props.state
        this.fn = props.fn
    }
    componentDidMount() {
        if (this.state.customer === undefined || this.state.customer === null) this.onRefresh()
    }
    onRefresh = () => {
        this.fn.post({
            url: this.fn.url("index"),
            success: (e, p) => {
                e = e.data
                e.list = []
                p = { i: 0, customer: {} }
                for (p.i in e.customer) p.customer[+e.customer[p.i].id] = e.customer[p.i].name
                e.customer = p.customer
                for (p.i in e.status) e.list.push(e.order.filter((expr) => expr.status === e.status[p.i].id))
                this.setState(this.fn.setState(e))
            }
        })
    }
    render() {
        return (
            <div id="kanban" className="w-100 h-100">
                {(this.state.list ?? []).map((tasks, listIndex, status) => {
                    status = this.state.status[listIndex]
                    return (
                        <>
                            <Popup
                                width={"90%"}
                                height={"90%"}
                                container="#kanban"
                                position={{ my: "center", at: "center", of: "#kanban" }}
                                dragEnabled={false}
                                onInitialized={(e) => this.setState({ kanban: e.component })}
                                deferRendering={false}
                            >
                                <ScrollView direction="vertical" showScrollbar="onHover">
                                    <DashboardHeader header={this.state.header} />
                                    <DataGrid
                                        onInitialized={(e) => this.setState({ grid: e.component })}
                                        columnAutoWidth={true}
                                        columnFixing={{ enabled: true }}
                                        width={"100%"}
                                        export={{ enabled: true }}
                                    >
                                        <Column
                                            dataField={"item_id"}
                                            lookup={{ dataSource: this.state.item, valueExpr: "id", displayExpr: "name" }}
                                            caption="Item"
                                        />
                                        <Column
                                            dataField={"qty"}
                                            caption="Qty"
                                            editorOptions="dxNumberBox"
                                            dataType="number"
                                            format="#,##0.##;-#,##0.##"
                                        ></Column>
                                        <Column dataField={"unit_id"} caption="Unit"></Column>
                                        <Column
                                            dataField={"price"}
                                            caption="Price"
                                            editorOptions="dxNumberBox"
                                            dataType="number"
                                            format="#,##0.##;-#,##0.##"
                                        ></Column>
                                        <Column
                                            dataField={"total"}
                                            caption="Total"
                                            editorOptions="dxNumberBox"
                                            dataType="number"
                                            format="#,##0.##;-#,##0.##"
                                        ></Column>
                                    </DataGrid>
                                </ScrollView>
                            </Popup>
                            {this.state.kanban !== undefined && this.state.kanban !== null ? (
                                <DashboardList
                                    title={status.name}
                                    index={listIndex}
                                    tasks={tasks}
                                    customer={this.state.customer}
                                    onTaskDragStart={this.onTaskDragStart}
                                    onTaskDrop={this.onTaskDrop}
                                    fn={this.fn}
                                    updateState={this.updateState}
                                    kanban={this.state.kanban}
                                />
                            ) : (
                                <></>
                            )}
                        </>
                    )
                })}
            </div>
        )
    }
    updateState = (e) => {
        this.state.grid.beginUpdate()
        this.state.grid.option("dataSource", e.detail)
        this.state.grid.option("onToolbarPreparing", (x) => this.onToolbarPreparing(x, e.status, e.id))
        this.state.grid.endUpdate()
        this.setState({
            header: e.header
        })
    }
    onToolbarPreparing = (e, value, id) => {
        e.toolbarOptions.items.push({
            location: "before",
            widget: "dxSelectBox",
            options: {
                width: 220,
                items: this.state.status ?? [],
                displayExpr: "name",
                valueExpr: "id",
                value: value,
                onValueChanged: (e) => {
                    this.fn.post({
                        url: this.fn.url("set"),
                        data: { previous: e.previousValue, status: e.value, id: id },
                        success: (res, p) => {
                            this.state.kanban.option("visible", false)
                            console.log(res)
                            setTimeout(() => this.onRefresh())
                        }
                    })
                }
            }
        })
    }
    renderContent = () => {
        return this.state.customer === undefined || this.state.customer === null ? (
            <>
                <span className="fa fa-spin fa-gear mr-1"></span>
                <span>Please wait ...</span>
            </>
        ) : (
            <div id="kanban" className="w-100 h-100">
                <ScrollView className="scrollable-board" direction="horizontal" showScrollbar="always">
                    <Sortable className="sortable-lists" itemOrientation="horizontal" handle=".list-title" onReorder={this.onListReorder}>
                        {this.state.list.map((tasks, listIndex, status) => {
                            status = this.state.status[listIndex]
                            return (
                                <this.List
                                    key={status.id}
                                    title={status.name}
                                    index={listIndex}
                                    tasks={tasks}
                                    customer={this.state.customer}
                                    onTaskDragStart={this.onTaskDragStart}
                                    onTaskDrop={this.onTaskDrop}
                                ></this.List>
                            )
                        })}
                    </Sortable>
                </ScrollView>
                <this.renderDetail />
            </div>
        )
    }
    onListReorder = (e) => {
        // this.setState({
        //     list: this.reorder(this.state.list, this.state.list[e.fromIndex], e.fromIndex, e.toIndex),
        //     status: this.reorder(this.state.status, this.state.status[e.fromIndex], e.fromIndex, e.toIndex)
        // })
    }

    onTaskDragStart = (e) => (e.itemData = this.state.list[e.fromData][e.fromIndex])
    onTaskDrop = (e) => {
        if (e.itemData.status !== this.state.status[e.toData].id)
            this.fn.post({
                url: this.fn.url("set"),
                data: { id: e.itemData.id, status: this.state.status[e.toData].id },
                success: (res) => setTimeout(() => this.onRefresh())
            })
        else this.onTaskDropNext(e)
    }
    onTaskDropNext = (e) => {
        this.updateTask(e.fromData, e.itemData, e.fromIndex, -1)
        this.updateTask(e.toData, e.itemData, -1, e.toIndex)
    }

    reorder(items, item, fromIndex, toIndex) {
        let result = items
        if (fromIndex >= 0) result = [...items.slice(0, fromIndex), ...items.slice(fromIndex + 1)]
        if (toIndex >= 0) result = [...items.slice(0, toIndex), item, ...items.slice(toIndex)]
        return result
    }
    updateTask(listIndex, itemData, fromIndex, toIndex, list) {
        list = this.state.list.slice()
        list[listIndex] = this.reorder(list[listIndex], itemData, fromIndex, toIndex)
        this.setState({
            list
        })
    }
}
export default Dashboard
