import { PostData } from "../../../Helper"
import { onRefresh } from "./event"

export const declare = ($, e, i) => {
    $.state.prepared = false
    $.lastUpdate = null
    $.flag = $.state.info.id.substr($.state.info.parent.length + 1)
    $.info = $.state.info
    $.access = $.info.access

    $.state.config = $.fn.config($.flag)
    $.dataSource = $.fn.data($.flag)
    if ($.dataSource.length > 0) $.lastUpdate = $.dataSource[0].updated_at
    $.continueSave = false

    $.waiting = false
    $.collection = {}
    if ($.state.config !== undefined && $.state.config !== null) {
        e = { extra: $.state.config.extra ?? [], config: $.state.config }
        for (i in e.extra) $.collection[e.extra[i]] = null
        $.editing = e.config.editing
    } else if ($.lastUpdate !== null) {
        $.collection[$.flag] = $.lastUpdate
    }
    if ($.editing === undefined || $.editing === null) $.editing = "cell"
    $.old = null
    $.editor = {}
    $.state.form = undefined
    $.details = {}
    $.forParent = null
    $.updatedAt = null
    $.post = PostData
    $.selectedGroup = {}
    $.maxDate = undefined
    $.minDate = undefined
    $.filter = {}
    try {
        $._ = JSON.parse(localStorage.getItem(window.tokenInfo))
        $.__ = $._.msg
    } catch (ex) {
        $.__ = {}
    }
    $.imageCollection = {};
    $.info.onRefresh = (e) => onRefresh($, e)
    $.extra = ""
    return $
}
