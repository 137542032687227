import React from "react"
import DateBox from 'devextreme-react/date-box';
import DropDownBox from 'devextreme-react/drop-down-box';
import { PostData } from "../../../Helper";
import DataGrid, {
    Selection, Paging, FilterRow, Scrolling,Column,
  } from 'devextreme-react/data-grid';

  import { Button } from 'devextreme-react/button';

const date = new Date(2018, 9, 16, 15, 8, 12);
const gridColumns = ['id', 'name'];
const columns = ['date', 'City', 'State', 'Phone', 'Fax'];

export class GeneralJournalReport extends React.Component {
    constructor(props) {
        super(props)
        //this.state = props.state
        this.state = {
            ...props,
            dateFrom: "",
            dateTo: "",
            kdcoa: "",
            loadcoa: [],
            gridBoxValue: [3],
            loadgridjournal:[],
            
           
        }
        this.fn = props.fn
        this.now = new Date();
        //console.log(window.url)
        //console.log(props);
        console.log("halo")
        
        this.onChangeDateFrom = this.onChangeDateFrom.bind(this);
        this.onChangeDateTo = this.onChangeDateTo.bind(this);
        this.onChangeCoa = this.onChangeCoa.bind(this);
        this.syncDataGridSelection = this.syncDataGridSelection.bind(this);
        this.dataGridRender = this.dataGridRender.bind(this);
        this.dataGridOnSelectionChanged = this.dataGridOnSelectionChanged.bind(this);
        
    }




    onChangeDateFrom(e) {
        //console.log(e.value);
        this.setState({
          dateFrom: e.value,
        });
      }

      onChangeDateTo(e) {
        //console.log(e.value);
        this.setState({
          dateTo: e.value,
        });
      }

      onChangeCoa(e) {
        //console.log(e.value);
        this.setState({
            kdcoa: e.value,
        });
      }

    componentDidMount() {

        PostData({url:"report/journal_report/getcoa",success:(res)=>{
            //console.log(res);
            this.setState({ loadcoa: res });

        }})

    }

    onFilter = (e) => {
        
        
        PostData({url:"report/journal_report/getjournalreportfilter",data:{coa:this.state.gridBoxValue,fromdate:this.state.dateFrom,todate:this.state.dateTo},showLoading:true,success:(res)=>{
            
            this.setState({ loadgridjournal: res });

        }})
      }

    onRefresh = () => {}
    render() {
        return (
            
            <div className="row">
                <div className="col-md-12">Filter</div>
                <div className="col-md-12">   
                    <div className="dx-field">
                        <div className="dx-field-label">From:</div>
                        <div className="dx-field-value">
                            <DateBox
                            defaultValue={date}
                            //placeholder="2021-10-16"
                            showClearButton={true}
                            useMaskBehavior={true}
                            type="date"
                            displayFormat="yyyy-MM-dd"
                            onValueChanged={this.onChangeDateFrom}
                            value={this.state.dateFrom}
                            />
                        </div>
                    </div>


                    <div className="dx-field">
                        <div className="dx-field-label">To:</div>
                        <div className="dx-field-value">
                            <DateBox
                            defaultValue={date}
                            //placeholder="2021-10-16"
                            showClearButton={true}
                            useMaskBehavior={true}
                            type="date"
                            displayFormat="yyyy-MM-dd"
                            onValueChanged={this.onChangeDateTo}
                            value={this.state.dateTo}
                            />
                        </div>
                    </div>
                </div>

                <div className="col-md-12">
                   

                  
                    <div className="dx-field">
                    <div className="dx-field-label">Coa</div>
                    <div className="dx-field-value">
                        <DropDownBox
                        value={this.state.gridBoxValue}
                        valueExpr="id"
                        deferRendering={false}
                        displayExpr="name"
                        placeholder="Select a value..."
                        showClearButton={true}
                        dataSource={this.state.loadcoa}
                        onValueChanged={this.syncDataGridSelection}
                        contentRender={this.dataGridRender}
                        />
                    </div>
                    </div>


                    
                </div>
                <div className="col-md-12">
                    
                    
                        <Button
                        width={120}
                        text="Filter"
                        type="normal"
                        stylingMode="contained"
                        onClick={this.onFilter}
                        />
                    
                    
                </div>

                
                <div className="row">
                <div className="col-md-12">
                <DataGrid
                    dataSource={this.state.loadgridjournal}
                    keyExpr="ID"
                    showBorders={true}
                >
                    
                    <Column dataField="date"
                    text="Tanggal"
                    width="200"
                    dataType="date"
                    format="dd-MM-y"
                    allowSorting={false}
                    
                    />    
                    <Column dataField="coa_id"
                    text="Kode Akun"
                    width="200"
                    dataType="string"
                    allowSorting={false}
                    
                    />    
                    <Column dataField="coa_name"
                    text="Nama Akun"
                    width="850"
                    dataType="string"
                    allowSorting={false}
                    
                    />    
                    <Column dataField="nominal"
                    text="Total"
                    width="400"
                    dataType="double"
                    format="0.##"
                    allowSorting={false}
                    
                    />   
                </DataGrid>
                </div>
                </div>

                
                    
            </div> 
        )
    }

    dataGridRender() {
        return (
          <DataGrid
            height={345}
            dataSource={this.state.loadcoa}
            columns={gridColumns}
            hoverStateEnabled={true}
            selectedRowKeys={this.state.gridBoxValue}
            onSelectionChanged={this.dataGridOnSelectionChanged}>
            <Selection mode="multiple" />
            <Scrolling mode="virtual" />
            <Paging enabled={true} pageSize={10} />
            <FilterRow visible={true} />
          </DataGrid>
        );
      }
    

    syncDataGridSelection(e) {
        this.setState({
          gridBoxValue: e.value || [],
        });
      }

      dataGridOnSelectionChanged(e) {
        //console.log(e);
        this.setState({
          gridBoxValue: (e.selectedRowKeys.length && e.selectedRowKeys) || [],
        });
        //console.log(this.state.gridBoxValue);
      }
}
export default GeneralJournalReport;