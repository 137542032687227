import React from "react"
import { PostData } from "../../../Helper"
import { onFilterDate, onShowPopup } from "../default/event"
import { Column, Editing } from "devextreme-react/data-grid"
import { ButtonItem, GroupItem, SimpleItem, Tab, TabbedItem } from "devextreme-react/form"
import { DataGrid, FileUploader, Form, Popup, ScrollView, TreeList } from "devextreme-react"
import { confirm } from "devextreme/ui/dialog"

export const bdoToolbarClick = ($, e, x, i) => {
    x = { cancel: false }
    x.selected = $.grid.getSelectedRowsData() ?? []
    x.selectedDownPayment = x.selected.filter((expr) => expr.detail === "invoice_espay_down_payment")
    x.selectedDownPayment = x.selectedDownPayment.length > 0 ? x.selectedDownPayment[0] : null
    if (e.event !== "BDO-delete") x.selected = x.selected.filter((expr) => expr.detail !== "invoice_espay_down_payment")
    if (x.selected.length <= 0) return window.toastr.warning($.__.require.selected)
    x.sum = 0
    x.message = "<div>" + $.__.dialog.wantToDeleteMultiple + "</div>"
    for (i in x.selected) {
        x.sum += +x.selected[i].receivable_balance
        if (x.customer_id === undefined || x.customer_id === null) x.customer_id = x.selected[i].customer_id
        if (x.selected[i].customer_id !== x.customer_id) return window.toastr.warning($.__.selection.singleCustomer)
        x.message += "<div class='pl-3'>" + x.selected[i].id + "</div>"
    }
    x.customer_name = x.customer_id
    x.customer = $.fn.data("customer") ?? []
    if (x.customer.length > 0)
        if ((x.filter = x.customer.filter((expr) => expr.id === x.customer_id)).length > 0)
            for (i in x.filter) x.customer_name += " - " + x.filter[i].name
    console.log(x.selectedDownPayment)
    x.formData = {
        bank_id: null,
        payment_promise_date: null,
        value: x.sum,
        reason_id: null,
        description: null,
        detail: [...x.selected],
        customer_id: x.customer_id,
        customer_name: x.customer_name,
        status: e.event.split("-")[1],
        down_payment_id: null,
        down_payment_amount: 0,
        down_payment_left: 0,
        selected_down_payment: x.selectedDownPayment
    }
    if (e.event === "BDO-delete") {
        confirm("<div style='font-style:italic!important;'>" + x.message + "</div>", $.__.dialog.title).then((res) =>
            res
                ? PostData({
                      url: $.fn.url("delete")
                  })
                : (x.cancel = true)
        )
    } else if (e.event === "BDO-downPayment") {
        PostData({
            url: $.fn.url("downPaymentCustomer"),
            data: { customer_id: x.customer_id, selectedDownPayment: x.selectedDownPayment === null ? null : x.selectedDownPayment.id },
            showLoading: true,
            success: (res, a) => {
                x.formData.downPaymentCustomer = res.data
                a = {}
                if (res.selectedDownPayment !== null && res.selectedDownPayment !== undefined) {
                    if ((a.check = res.data.filter((expr) => expr.id === res.selectedDownPayment)).length > 0) {
                        x.formData.down_payment_id = a.check[0].id
                        x.formData.down_payment_amount = +a.check[0].amount
                        x.formData.down_payment_left = x.formData.down_payment_amount - x.formData.value
                    }
                }
                $.form.BDOFollowUp.option("formData", x.formData)
                setTimeout(() => onShowPopup($.popup.BDOFollowUp, { title: x.customer_name }))
            }
        })
    } else if (x.sum < 0) {
        return window.toastr.warning($.__.selection.moreThanZero)
    } else {
        $.form.BDOFollowUp.option("formData", x.formData)
        onShowPopup($.popup.BDOFollowUp, { title: x.customer_name })
    }
}

export const onBDOFollowUpEmpty = ($, a, formData, i) => {
    a = { data: [...$.dataGrid.BDOFollowUp.option("dataSource")] }
    for (i in a.data) {
        a.data[i].paid = 0
        a.data[i].down_payment = 0
        a.data[i].left = a.data[i].receivable_balance
    }
    $.dataGrid.BDOFollowUp.option("dataSource", a.data)
    formData = $.form.BDOFollowUp.option("formData")
    formData.value = 0
    formData.down_payment_left = formData.down_payment_amount - formData.value
    $.form.BDOFollowUp.option("formData", formData)
}
export const onBDOFollowUpPayAll = ($, a, formData, i) => {
    a = { data: [...$.dataGrid.BDOFollowUp.option("dataSource")], sum: 0 }
    for (i in a.data) {
        a.data[i].paid = a.data[i].paid !== undefined && a.data[i].paid !== null ? a.data[i].receivable_balance : 0
        a.data[i].down_payment = a.data[i].down_payment !== undefined && a.data[i].down_payment !== null ? a.data[i].receivable_balance : 0
        a.data[i].left = 0
        a.sum += +a.data[i].paid + +a.data[i].down_payment
    }
    $.dataGrid.BDOFollowUp.option("dataSource", a.data)
    formData = $.form.BDOFollowUp.option("formData")
    formData.value = a.sum
    formData.down_payment_left = formData.down_payment_amount - formData.value
    $.form.BDOFollowUp.option("formData", formData)
}

export class BdoRenderPopupFollowUp extends React.Component {
    constructor(props) {
        super(props)
        this.state = { ...props }
    }
    render($, x) {
        $ = this.props.parent
        if ($.popup === undefined) $.popup = {}
        if ($.form === undefined) $.form = {}
        x = { input: {} }
        return (
            <Popup
                showTitle={true}
                dragEnabled={false}
                container={"#pg-" + $.flag}
                position={{
                    my: "center",
                    at: "center",
                    of: "#pg-" + $.flag
                }}
                showCloseButton={true}
                onInitialized={(e) => {
                    $.popup.BDOFollowUp = e.component
                    e.component.option("visible", false)
                }}
                onShowing={(e, i, data, form, a) => {
                    form = $.form.BDOFollowUp
                    data = form.option("formData")
                    x.tempBank = $.fn.data("bank")
                    x.dataBank = []
                    a = { sum: 0 }
                    for (i in x.tempBank)
                        x.dataBank.push({ id: x.tempBank[i].id, name: x.tempBank[i].name + " (" + x.tempBank[i].account_number + ")" })
                    x.detail = [...data.detail]
                    for (i in x.detail) {
                        x.detail[i].receivable_balance_display = +x.detail[i].receivable_balance //+ (data.status === "downPayment" ? +x.detail[i].down_payment : 0)
                        x.detail[i].paid = /*data.status === "downPayment" ? +x.detail[i].down_payment :*/ +x.detail[i].receivable_balance
                        x.detail[i].left = x.detail[i].receivable_balance_display - x.detail[i].paid
                        a.sum += x.detail[i].paid
                    }
                    $.dataGrid.BDOFollowUp.beginUpdate()
                    $.dataGrid.BDOFollowUp.option("dataSource", x.detail)
                    $.dataGrid.BDOFollowUp.endUpdate()

                    form.beginUpdate()
                    data.value = a.sum
                    data.detail = x.detail
                    form.option("formData", data)
                    form.itemOption("tabs[0].bank_id", "visible", data.status === "followUp")
                    form.itemOption("tabs[0].payment_promise_date", "visible", data.status === "followUp")
                    form.itemOption("tabs[0].reason_id", "visible", data.status === "followUp")
                    form.itemOption("tabs[0].files", "visible", data.status === "followUp")
                    form.itemOption("tabs[0].down_payment_id", "visible", data.status === "downPayment")
                    form.itemOption("tabs[0].down_payment_amount", "visible", data.status === "downPayment")
                    form.itemOption("tabs[0].down_payment_left", "visible", data.status === "downPayment")
                    form.endUpdate()

                    x.input.bank_id.option("items", x.dataBank)
                    x.input.reason_id.option("items", $.fn.data("reason"))

                    x.input.down_payment_id.option("items", data.downPaymentCustomer)
                }}
            >
                <ScrollView width={"100%"} height={"100%"}>
                    <form
                        onSubmit={(e, fd, a) => {
                            e.preventDefault()
                            fd = new FormData(e.target)
                            a = {
                                value: +(fd.get("value") ?? "0"),
                                detail: $.dataGrid.BDOFollowUp.option("dataSource") ?? [],
                                formData: $.form.BDOFollowUp.option("formData")
                            }
                            switch (a.formData.status) {
                                case "followUp":
                                    if (a.value < 0) return window.toastr.warning($.__.selection.moreThanZero)
                                    break
                                case "downPayment":
                                    if (a.value === 0) return window.toastr.warning($.__.require.selected)
                                    break
                            }
                            if (a.detail.length <= 0) return window.toastr.warning($.__.require.selected)
                            fd.append("detail", JSON.stringify(a.detail))
                            if (e.target.files !== undefined && e.target.files !== null)
                                if (window.$(e.target.files)[0].files.length > 0)
                                    if (window.$(e.target.files)[0].files[0].size > 500000) return window.toastr.warning($.__.limit.file + " 500kb")
                            PostData({
                                url: $.fn.url(a.formData.status),
                                data: fd,
                                success: (res) => {
                                    $.grid.deselectAll()
                                    $.popup.BDOFollowUp.hide()
                                    window.toastr.success($.__.success)
                                    onFilterDate($)
                                },
                                showLoading: true
                            })
                        }}
                    >
                        <Form onContentReady={(e) => ($.form.BDOFollowUp = e.component)} colCount={"auto"}>
                            <TabbedItem tabPanelOptions={{ deferRendering: false }}>
                                <Tab title="Information">
                                    <GroupItem>
                                        <SimpleItem
                                            dataField="bank_id"
                                            editorType={"dxSelectBox"}
                                            label={"Bank"}
                                            editorOptions={{
                                                valueExpr: "id",
                                                displayExpr: "name",
                                                onContentReady: (e) => (x.input.bank_id = e.component)
                                            }}
                                        />
                                        <SimpleItem
                                            dataField="payment_promise_date"
                                            label={$.__.column.payment_promise_date}
                                            editorType={"dxDateBox"}
                                            editorOptions={{
                                                displayFormat: "dd MMM yyyy",
                                                onContentReady: (e) => (x.input.payment_promise_date = e.component)
                                            }}
                                        />
                                        <SimpleItem
                                            dataField="down_payment_id"
                                            editorType={"dxSelectBox"}
                                            label={$.__.column.down_payment_id}
                                            editorOptions={{
                                                valueExpr: "id",
                                                displayExpr: "id",
                                                onContentReady: (e) => (x.input.down_payment_id = e.component),
                                                onValueChanged: (e, data, formData) => {
                                                    formData = $.form.BDOFollowUp.option("formData")
                                                    data = (formData.downPaymentCustomer ?? []).filter((expr) => expr.id === e.value)
                                                    formData.down_payment_amount = data.length <= 0 ? 0 : +data[0].amount
                                                    formData.down_payment_left = formData.down_payment_amount - formData.value
                                                    $.form.BDOFollowUp.option("formData", formData)
                                                }
                                            }}
                                            isRequired={true}
                                        />
                                        <SimpleItem
                                            dataField="down_payment_amount"
                                            label={$.__.column.down_payment_amount}
                                            editorType={"dxNumberBox"}
                                            editorOptions={{
                                                format: "#,##0.##;-#,##0.##",
                                                onContentReady: (e) => (x.input.down_payment_amount = e.component),
                                                readOnly: true
                                            }}
                                            isRequired={true}
                                        />
                                        <SimpleItem
                                            dataField="value"
                                            label={$.__.column.value}
                                            editorType={"dxNumberBox"}
                                            editorOptions={{
                                                format: "#,##0.##;-#,##0.##",
                                                onContentReady: (e) => (x.input.value = e.component),
                                                readOnly: true
                                            }}
                                            isRequired={true}
                                        />
                                        <SimpleItem
                                            dataField="down_payment_left"
                                            label={$.__.column.down_payment_left}
                                            editorType={"dxNumberBox"}
                                            editorOptions={{
                                                format: "#,##0.##;-#,##0.##",
                                                onContentReady: (e) => (x.input.down_payment_left = e.component),
                                                readOnly: true
                                            }}
                                            isRequired={true}
                                        />
                                        <SimpleItem
                                            dataField="reason_id"
                                            editorType={"dxSelectBox"}
                                            label={$.__.reason_id}
                                            editorOptions={{
                                                valueExpr: "id",
                                                displayExpr: "name",
                                                onContentReady: (e) => (x.input.reason_id = e.component)
                                            }}
                                        />
                                        <SimpleItem dataField="description" label={$.__.column.value} editorType={"dxTextArea"} />
                                        <SimpleItem
                                            dataField="files"
                                            selectButtonText={$.__.column.image}
                                            editorType={"dxFileUploader"}
                                            editorOptions={{
                                                accept: "image/*",
                                                uploadMode: "useForm",
                                                labelText: "Maximum file size 500kb",
                                                onContentReady: (e) => (x.input.files = e.component),
                                                onValueChanged: (e, v, i, mx) => {
                                                    if ((v = e.value ?? []).length <= 0) return
                                                    else mx = 500000
                                                    for (i in v) {
                                                        if (v[i].size > mx) {
                                                            window.toastr.warning(
                                                                "Maximum file size " +
                                                                    Math.round(mx / 1000) +
                                                                    "kb<br />[" +
                                                                    v[i].name +
                                                                    "] " +
                                                                    Math.round(v[i].size / 1000) +
                                                                    "kb"
                                                            )
                                                            x.input.files.reset()
                                                        }
                                                    }
                                                }
                                            }}
                                        />
                                    </GroupItem>
                                    <GroupItem colCount={3}>
                                        <ButtonItem
                                            horizontalAlignment="right"
                                            buttonOptions={{ text: $.__.empty, onClick: () => onBDOFollowUpEmpty($) }}
                                        />
                                        <ButtonItem
                                            horizontalAlignment="center"
                                            buttonOptions={{ text: $.__.save, type: "success", useSubmitBehavior: true }}
                                        />
                                        <ButtonItem
                                            horizontalAlignment="left"
                                            buttonOptions={{ text: $.__.payAll, onClick: () => onBDOFollowUpPayAll($) }}
                                        />
                                    </GroupItem>
                                </Tab>
                                <Tab title="Detail">
                                    <BdoRenderGridFollowUp parent={$}></BdoRenderGridFollowUp>
                                </Tab>
                            </TabbedItem>
                        </Form>
                    </form>
                </ScrollView>
            </Popup>
        )
    }
}
export class BdoRenderGridFollowUp extends React.Component {
    constructor(props) {
        super(props)
    }
    render($, x, i) {
        $ = this.props.parent
        if ($.dataGrid === undefined) $.dataGrid = {}
        x = { columns: $.grid.option("columns"), column: {} }
        for (i in x.columns) x.column[x.columns[i].dataField] = x.columns[i]
        return (
            <DataGrid
                onContentReady={(e) => ($.dataGrid.BDOFollowUp = e.component)}
                loadPanel={{ enabled: false }}
                onRowRemoved={(e, formData) => {
                    formData = $.form.BDOFollowUp.option("formData")
                    formData.value -= e.data.paid
                    formData.down_payment_left = formData.down_payment_amount - formData.value
                    $.form.BDOFollowUp.option("formData", formData)
                    if (($.dataGrid.BDOFollowUp.option("dataSource") ?? []).length <= 0) $.popup.BDOFollowUp.hide()
                }}
                onToolbarPreparing={(e) => {
                    e.toolbarOptions.items.push({
                        location: "after",
                        widget: "dxButton",
                        options: {
                            text: $.__.empty,
                            onClick: () => onBDOFollowUpEmpty($)
                        }
                    })
                    e.toolbarOptions.items.push({
                        location: "after",
                        widget: "dxButton",
                        options: {
                            text: $.__.payAll,
                            onClick: () => onBDOFollowUpPayAll($)
                        }
                    })
                }}
            >
                <Editing allowDeleting={true} allowUpdating={true} useIcons={true} mode={"cell"} />
                <Column dataField={"id"} caption={x.column.id.caption} allowEditing={false}></Column>
                <Column
                    allowEditing={false}
                    dataField={"receivable_balance_display"}
                    caption={x.column.receivable_balance.caption}
                    dataType="number"
                    format={x.column.receivable_balance.format}
                ></Column>
                <Column
                    dataField={"paid"}
                    caption={$.__.column.paid}
                    dataType="number"
                    format={x.column.receivable_balance.format}
                    editorOptions={{ format: x.column.receivable_balance.editorOptions.format }}
                    setCellValue={(data, value, old, formData) => {
                        formData = $.form.BDOFollowUp.option("formData")
                        formData.value -= +(old.paid ?? 0)
                        data.paid = value
                        data.left = +(data.receivable_balance_display ?? old.receivable_balance_display ?? 0) - data.paid
                        formData.value += +(data.paid ?? 0)
                        formData.down_payment_left = formData.down_payment_amount - formData.value
                        $.form.BDOFollowUp.option("formData", formData)
                    }}
                ></Column>
                <Column
                    allowEditing={false}
                    dataField={"left"}
                    caption={$.__.column.left}
                    dataType="number"
                    format={x.column.receivable_balance.format}
                ></Column>
            </DataGrid>
        )
    }
}
