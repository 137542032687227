import React from "react"
class DashboardCard extends React.Component {
    constructor(props) {
        super(props)
        this.fn = this.props.fn
    }
    componentDidMount() {}
    render() {
        return (
            <div className="card dx-card dx-theme-text-color dx-theme-background-color">
                <div className="card-subject">{this.props.task.id}</div>
                <div className="card-assignee">
                    <span
                        className="fa fa-eye p-2 cursor-pointer mr-1"
                        data-id={this.props.task.id}
                        onClick={(e) => {
                            this.fn.post({
                                url: this.fn.url("get"),
                                data: { id: e.target.getAttribute("data-id") },
                                success: (e) => {
                                    this.props.kanban.beginUpdate()
                                    this.props.kanban.option("visible", true)
                                    this.props.kanban.option("title", e.title)
                                    this.props.kanban.endUpdate()
                                    this.props.updateState({
                                        detail: e.data,
                                        header: e.header,
                                        status: e.status,
                                        id: e.id
                                    })
                                }
                            })
                        }}
                    ></span>
                    {this.props.customer[+this.props.task.customer_id]}
                </div>
            </div>
        )
    }
}
export default DashboardCard
