import React, { Suspense } from "react"
class MainContent extends React.Component {
    constructor(props) {
        super(props)
        this.content = props.content
        this.state = props.state
        this.logout = props.logout

        this.id = this.state.info.id
        this.url = this.state.info.id.split(".").join("/")
        if (this.url.substr(this.url.length - 1, 1) !== "/") this.url += "/"
        this.month = ["", "Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]
        this.fn = {
            url: (e) => this.url + e,
            data: this.content.data,
            column: this.content.column,
            detail: this.content.detail,
            config: this.content.config,
            money: (e) => {
                if (isNaN(e)) e = 0
                else e *= 1
                if (e >= 1000000000000) return Math.round((e / 1000000000000) * 100) / 100 + " Tr"
                else if (e >= 1000000000) return Math.round((e / 1000000000) * 100) / 100 + " Mil"
                else if (e >= 1000000) return Math.round((e / 1000000) * 100) / 100 + " Jt"
                else if (e >= 1000) return Math.round((e / 1000) * 100) / 100 + " Rb"
                else e.toLocaleString("en-US")
            },
            dmy: (e) => {
                if (e === null || e === undefined) e = ""
                if (e === "") return ""
                e = { ori: e, dateTime: e.split(" "), output: "" }
                e.date = e.dateTime[0].split("-")
                e.output = e.date[2] + " " + this.month[+e.date[1]] + " " + e.date[0]
                if (e.dateTime.length > 1) e.output += " " + e.dateTime[1]
                return e.output
            },
            dm: (e) => {
                if (e === null || e === undefined) e = ""
                if (e === "") return ""
                e = { ori: e, dateTime: e.split(" "), output: "" }
                e.date = e.dateTime[0].split("-")
                e.output = e.date[2] + " " + this.month[+e.date[1]]
                if (e.dateTime.length > 1) e.output += " " + e.dateTime[1]
                return e.output
            },
            setState: (e) => {
                for (this.i in e) this.state[this.i] = e[this.i]
                return e
            },
            month: (e) => {
                return this.month[+e]
            },
            logout: this.state.logout
        }
        this.token = localStorage.getItem(window.token)
        if (this.token === undefined || this.token === null) this.token = ""
        this.info = JSON.parse(localStorage.getItem(window.tokenInfo))
        this.pageInfo = { ...this.state.info }
    }
    fileExists = (path) => {
        try {
            return require("../pages/" + path)
        } catch (err) {
            return null
        }
    }
    toProperCase = (str) => {
        let string = str.toLowerCase().replace(/(?:^|[\s-/_])\w/g, function (match) {
            return match.toUpperCase()
        })
        return string.replace("_", "")
    }
    renderMain = (e) => {
        e = this.pageInfo
        if (e.page === undefined)
            e.page = React.lazy(() =>
                import("../pages/" + e.fileName).catch((ex) => {
                    e.page = null
                })
            )
        return (
            <Suspense fallback={"Page '" + e.name + "' not found "}>
                {e.page === undefined || e.page === null ? (
                    "Page '" + e.name + "' not found "
                ) : (
                    <div className="w-100 h-100 position-relative" id={"pg-" + e.id.substr(e.parent.length + 1)}>
                        <div className="position-absolute top-0 left-0 w-100">
                            <span className="border-bottom">{e.name}</span>
                        </div>
                        <div className="w-100 h-100 pt-4">
                            <e.page state={this.state} fn={this.fn} catch={(ex) => console.log(ex)} />
                        </div>
                    </div>
                )}
            </Suspense>
        )
    }
    render() {
        return (
            <div className="mainContent width-100 height-100 dx-theme-background-color dx-theme-text-color p-1 position-relative">
                <this.renderMain />
            </div>
        )
    }
}
export default MainContent
