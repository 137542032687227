import React from "react"
import { Popup, TreeList } from "devextreme-react"
import { Column as ColumnTree } from "devextreme-react/tree-list"

export class RenderPopupUser extends React.Component {
    constructor(props) {
        super(props)
    }
    render($) {
        $ = this.props.parent
        if ($.popup === undefined) $.popup = {}
        if ($.tree === undefined) $.tree = {}
        return (
            <Popup
                showTitle={true}
                dragEnabled={false}
                container={"#pg-" + $.flag}
                position={{
                    my: "center",
                    at: "center",
                    of: "#pg-" + $.flag
                }}
                showCloseButton={true}
                onInitialized={(e) => {
                    $.popup.user = e.component
                    e.component.option("visible", false)
                }}
            >
                <TreeList
                    keyExpr="id"
                    parentIdExpr="parent"
                    displayExpr="name"
                    onInitialized={(e) => ($.tree.user = e.component)}
                    filterMode="fullBranch"
                    showColumnHeaders={false}
                    selection={{ mode: "multiple", recursive: true }}
                    height="100%"
                >
                    <ColumnTree
                        dataField="name"
                        allowSorting={false}
                        cellRender={(x) => {
                            return (
                                <>
                                    <span className={x.data.icon}></span>
                                    <span className="ml-1">{x.data.name}</span>
                                </>
                            )
                        }}
                    />
                </TreeList>
            </Popup>
        )
    }
}
