import React from "react"
import "bootstrap/dist/css/bootstrap.min.css"
import "font-awesome/css/font-awesome.css"
import "./style.css"
import Content from "./content"
import { changeKey, PostData } from "./Helper"
class App extends React.Component {
    constructor(props) {
        super(props)
        this.state = { ...props }
        if (window.token === undefined || window.token === null || window.token === "") window.token = localStorage.getItem("_key") ?? ""
        this.resetTokenName()
        if (window.token !== "") {
            window.tokenInfo = window.token + ".info"
            window.tokenTheme = window.token + ".theme"
            window.tokenUrl = window.token + ".url"
            window.tokenFront = window.token + ".front"
            window.tokenUnique = window.token + ".unique"
            window.tokenList = window.token + ".list"
            window.url = localStorage.getItem(window.tokenUrl) ?? "" //"api/public/api"
            this.state.info = ""
            if (this.state.info !== "") this.state.info = JSON.parse(this.state.info)
            changeKey(this.state.info)

            this.state.token = localStorage.getItem(window.token)
            if (this.state.token === undefined || this.state.token === null) this.state.token = ""
            this.fn = {
                logout: (e) => {
                    localStorage.clear()
                    window.location.reload()
                }
            }
            this.state.token = localStorage.getItem(window.token)
            this.state.front = localStorage.getItem(window.tokenFront) ?? ""
            if (this.state.info.menu === undefined || this.state.info.menu === null) this.state.info = ""
        }
        if (window.url === undefined || window.url === null || window.url === "") window.url = process.env.REACT_APP_API_URL
        if (window.url.substring(window.url.length - 1) !== "/") window.url += "/"
        //if ((this.state.token ?? "") === "") window.token = ""
        this.state.message = ""
        this.state.code = 200
        this.state.list = localStorage.getItem(window.tokenList) ?? []
        if (typeof this.state.list === "string")
            try {
                this.state.list = JSON.parse(this.state.list)
            } catch (ex) {}
    }
    resetTokenName = () => {
        this.name = process.env.REACT_APP_PROJECT_NAME
        if (this.name === undefined || this.name === null || this.name === "null") this.name = ""
        if (this.name !== "") {
            if (window.token === "null" || window.token === "" || window.token === undefined || window.token === null || window.resetToken) {
                localStorage.setItem("_key", (window.token = this.name))
                localStorage.setItem(window.token + ".url", process.env.REACT_APP_API_URL)
            }
            window.tokenName = window.token + ".name"
            localStorage.setItem(window.tokenName, this.name)
        } else {
            window.tokenName = ""
        }
    }
    setList = (e) => {
        localStorage.setItem(window.tokenList, JSON.stringify(e))
        this.setState({ list: e })
    }
    // http://localhost:3000/?v=10856%7CspMbdd5UmwcMQysYXEaAlmaSuavwYnTUMmRGE4hO&n=BDO.BDO&u=http://localhost/office/bdo/public/api/&f=http://localhost/office/front
    componentDidMount(x) {
        if (window.token !== "")
            if (this.state.list.length <= 0)
                return PostData({
                    url: "list",
                    data: { sleep: 0 },
                    success: (e) => this.setList(e.data),
                    warning: (e) => this.setState({ message: e.message, info: "", isLoading: false, code: e.code }),
                    error: (e) => this.setState({ message: e.message, info: "", isLoading: false, code: e.code })
                })
            else if ((this.state.token ?? "") !== "")
                // && this.state.info === ""
                return PostData({
                    url: "user/info",
                    showLoading: true,
                    data: { sleep: 0 },
                    success: (e) => this.start(e),
                    warning: (e) => this.setState({ message: e.message, info: "", isLoading: false, code: e.code }),
                    error: (e) => this.setState({ message: e.message, info: "", isLoading: false, code: e.code })
                })
        // PostData({ url: "test" })
    }
    start = (e) => {
        e.data.info = e.data
        changeKey(e.data.info)
        e.data.isLoading = false
        e.data.code = 200
        this.setState(e.data)
    }
    render() {
        if (this.state.list === null || this.state.list === undefined)
            return (
                <div className="d-flex align-items-center height-100 text-left justify-content-center">
                    <span className="fa fa-circle-o-notch fa-spin mr-1"></span> Please wait...
                </div>
            )
        if ((this.state.token ?? "") === "" || [401, 402, 403].includes(this.state.code))
            return (
                <div className="d-flex align-items-center height-100 text-left justify-content-center">
                    <form
                        className="mw-90 bg-light-7 p-4 border shadow-sm"
                        style={{ width: "400px" }}
                        onSubmit={(e, fd) => {
                            e.preventDefault()
                            fd = new FormData(e.target)
                            fd.append("is_reset_password", this.state.isResetPassword ? 1 : 0)
                            fd.append("is_send_email", this.state.isSendEmail ? 1 : 0)
                            PostData({
                                url: "user/login",
                                data: fd,
                                success: (res) => {
                                    if (res.isSendEmail) this.setState({ isSendEmail: true })
                                    else this.start(res)
                                },
                                showLoading: true
                            })
                        }}
                    >
                        <div className="bg-contain logo w-100" style={{ height: 125 }}></div>
                        {this.state.list.length <= 0 ? (
                            <></>
                        ) : (
                            <select name="unique" className="form-control my-1" id="select-login-list">
                                {this.state.list.map((e) => (
                                    <option value={e.id}>{e.name}</option>
                                ))}
                            </select>
                        )}
                        <LabelInput id="text-login-username" label="Email / Username" type="text" name="username" />
                        {this.state.isResetPassword && this.state.isSendEmail ? (
                            <LabelInput id="text-login-otp" label="OTP" type="text" name="otp" />
                        ) : (
                            <></>
                        )}
                        {!this.state.isResetPassword || this.state.isSendEmail ? (
                            <LabelInput id="text-login-password" label="Password" type="password" name="password" />
                        ) : (
                            <></>
                        )}
                        {this.state.isResetPassword && this.state.isSendEmail ? (
                            <LabelInput id="text-login-confirm-password" label="Confirm Password" type="password" name="confirm_password" />
                        ) : (
                            <></>
                        )}

                        <button type={"submit"} className="btn btn-dark mt-2">
                            {this.state.isSendEmail ? "Change Password" : this.state.isResetPassword ? "Request OTP" : "Login"}
                        </button>
                        <div
                            className="btn btn-light ml-2 mt-2"
                            onClick={(e) => this.setState({ isResetPassword: !this.state.isResetPassword, isSendEmail: false })}
                        >
                            {this.state.isResetPassword ? "Login" : "Forget Password?"}
                        </div>
                    </form>
                </div>
            )
        // return (
        //     <Back message="Access Forbidden X" url={this.state.front !== "" && !this.state.isLoading ? this.state.front : ""} />
        //     // <div className="d-flex align-items-center w-100 h-100 justify-content-center font-lg">
        //     //     <Back message="Access Forbidden" url={this.state.front !== "" && !this.state.isLoading ? this.state.front : ""} />
        //     //     <span className="border-bottom border-1 border-dark">{"Access Forbidden"}</span>
        //     //     <br />
        //     //     <br />
        //     //     {this.state.front !== "" && !this.state.isLoading ? (
        //     //         <a href={this.state.front} className="mt-2" style={{ textDecoration: "none" }}>
        //     //             Back
        //     //         </a>
        //     //     ) : (
        //     //         ""
        //     //     )}
        //     // </div>
        // )
        if ((this.state.token ?? "") === "" && this.state.front !== "") {
            window.location.href = this.state.front
            return (
                <div className="d-flex align-items-center w-100 h-100 justify-content-center font-lg">
                    <span className="border-bottom border-1 border-dark">Good Bye.. See you later</span>
                </div>
            )
        }
        return (
            <div className="App w-100 h-100">
                {this.state.token !== "" && this.state.info !== "" ? (
                    <Content logout={this.fn.logout}></Content>
                ) : (
                    <Back
                        message={this.state.info === "" ? this.state.message : "Access Forbidden"}
                        url={this.state.front !== "" && !this.state.isLoading ? this.state.front : ""}
                    />
                    // <div className="d-flex align-items-center w-100 h-100 justify-content-center font-lg">
                    //     <div className="position-relative text-center">
                    //         <span className="border-bottom border-1 border-dark pb-4">
                    //             {this.state.info === "" ? this.state.message : "Access Forbidden"}
                    //         </span>
                    //         <br />
                    //         <br />
                    //         {this.state.front !== "" && !this.state.isLoading ? (
                    //             <a href={this.state.front} className="mt-2" style={{ textDecoration: "none" }}>
                    //                 Back
                    //             </a>
                    //         ) : (
                    //             ""
                    //         )}
                    //     </div>
                    // </div>
                )}
            </div>
        )
    }
}
export default App
export class LabelInput extends React.Component {
    render() {
        return (
            <>
                <label htmlFor={this.props.id}>{this.props.label}</label>
                <input type={this.props.type} name={this.props.name} className="form-control" id={this.props.id} required={true} />
            </>
        )
    }
}
export class Back extends React.Component {
    render() {
        return (
            <div className="d-flex align-items-center w-100 h-100 justify-content-center font-lg">
                <div className="position-relative text-center">
                    <span className="border-bottom border-1 border-dark pb-4">{this.props.message}</span>
                    <br />
                    <br />
                    {this.props.url !== "" ? (
                        <a href={this.props.url} className="mt-2" style={{ textDecoration: "none" }}>
                            Back
                        </a>
                    ) : (
                        ""
                    )}
                </div>
            </div>
        )
    }
}
