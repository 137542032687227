import axios from "axios"

export const changeKey = (x, e) => {
    if (x === undefined || x === null) return localStorage.getItem(window.token)
    if (x._key === undefined) return localStorage.getItem(window.token)
    if (e === undefined || e === null) e = {}
    e.old = {
        token: localStorage.getItem(window.token),
        tokenInfo: localStorage.getItem(window.tokenInfo),
        tokenTheme: localStorage.getItem(window.tokenTheme),
        tokenUrl: localStorage.getItem(window.tokenUrl),
        tokenFront: localStorage.getItem(window.tokenFront),
        tokenUnique: localStorage.getItem(window.tokenUnique),
        tokenList: localStorage.getItem(window.tokenList)
    }
    for (e.i in e.old) {
        e.temp = window[e.i].split(".")
        e.temp = e.temp[e.temp.length - 1]
        localStorage.removeItem(window[e.i])
        window[e.i] = x._key + "." + e.temp
        localStorage.setItem(window[e.i], e.old[e.i])
    }
    return localStorage.getItem(window.token)
}
export const BlobToImage = (blob, evt, reader) => {
    reader = new FileReader()
    reader.onloadend = () => (typeof evt === "function" ? evt(reader.result) : undefined)
    reader.readAsDataURL(blob)
}
export const PostData = (p, x, i, url) => {
    if (window.waitingList === undefined) window.waitingList = []
    if (i === undefined) {
        window.waitingList.push(p)
        if (window.waitingList.length > 1) return
    }
    x = {
        token: localStorage.getItem(window.token),
        loadingParam: {}
    }
    if (p.showLoading === undefined) p.showLoading = false
    if (p.showLoading) {
        if (p.loadingText !== undefined) x.loadingParam.text = p.loadingText
        x.loading = window.loading(x.loadingParam)
    }
    if (x.token === undefined || x.token === null) x.token = ""
    if (x.token !== "") if (x.token.substring(0, 7).toLowerCase() !== "bearer ") x.token = "Bearer " + x.token
    // if (p.url.substring(0, 1) !== "/") p.url = "/" + p.url
    // alert(window.token)
    x.headers = {
        "Access-Control-Allow-Origin": "*",
        Authorization: x.token,
        "data-key": window.token,
        "data-dir": process.env.REACT_APP_DIR,
        "data-target": process.env.REACT_APP_TARGET ?? "",
        "data-unique": localStorage.getItem(window.tokenUnique)
    }
    if (p.contentType !== undefined) x.headers["Content-Type"] = p.contentType
    if (window.tokenName !== "") x.headers["data-name"] = localStorage.getItem(window.tokenName)
    x.parameter = { headers: x.headers }
    if (p.responseType !== undefined) x.parameter.responseType = p.responseType
    if (window.imageCollection === undefined) window.imageCollection = {}
    if (window.imageCollection[p.url] !== undefined) {
        if (window.imageCollection[p.url] !== null) BlobToImage(window.imageCollection[p.url], p.success)
        window.waitingList.shift()
        if (window.waitingList.length > 0) PostData(window.waitingList[0], {}, 0)
        return null
    }
    url = p.url
    if (url.substr(0, 4) !== "http") url = window.url + url
    return axios
        .post(url, p.data, x.parameter)
        .then((rs, e, reader) => {
            if (x.loading !== undefined) x.loading.hide()
            e = rs.data
            if (p.responseType === "blob") {
                BlobToImage((window.imageCollection[p.url] = e), p.success)
            } else {
                if (typeof e === "string") e = { code: 200, message: e }

                if (e.code === undefined || e.code === null) e.code = 200
                if (e.code * 1 !== 200) {
                    if ([401, 402, 403].includes(+e.code)) {
                        // localStorage.removeItem(window.token)
                        // window.location.reload()
                    }
                    if (typeof p.warning === "function") p.warning(e)
                    else if (e.message !== undefined && e.message !== null && e.message !== "") window.toastr.warning(e.message)
                    if (typeof p.close === "function") p.close(e)
                } else {
                    if (typeof e.data === "object") {
                        if (e.data.token !== undefined) {
                            // if (window.token === undefined || window.token === null || window.token === "null" || window.token === "")
                            if (e.data.project !== undefined && e.data.project !== null && e.data.project !== "") {
                                window.token = e.data.project
                                localStorage.setItem("_key", window.token)
                            }
                            localStorage.setItem(window.token, e.data.token)
                            localStorage.setItem(window.tokenInfo, JSON.stringify(e.data))
                            localStorage.setItem(window.tokenUnique, e.data.unique)
                        }
                        changeKey(e.data)
                    }
                    if (typeof p.success === "function") p.success(e)
                    else if (e.message !== undefined && e.message !== null && e.message !== "") window.toastr.success(e.message)
                    if (typeof p.close === "function") p.close(e)
                }
            }
            window.waitingList.shift()
            if (window.waitingList.length > 0) PostData(window.waitingList[0], {}, 0)
        })
        .catch((e) => {
            if (p.responseType === "blob") {
                window.imageCollection[p.url] = null
            } else {
                if (x.loading !== undefined) x.loading.hide()
                if (typeof e === "string") e = { message: e }
                if (e.response) {
                    e.code = +(e.response.status ?? "400")
                    if ([401, 402, 403].includes(e.code)) {
                        localStorage.removeItem(window.token)
                        if (typeof p.error === "function") p.error(e)
                        window.location.reload()
                    } else {
                        e.message = e.response.data.message
                        if (typeof p.error === "function") p.error(e)
                        else window.toastr.error(e.response.data.message)
                    }
                } else {
                    if (typeof e.message !== "object") e.message = (isNaN(e.code) ? "" : e.code + " - ") + e.message
                    if (e.message.split("401").length <= 1 && e.message.split("402").length <= 1 && e.message.split("403").length <= 1) {
                        if (typeof p.error === "function") p.error(e)
                        else window.toastr.error(e.message)
                    } else {
                        localStorage.removeItem(window.token)
                        window.location.reload()
                    }
                }
            }
            window.waitingList.shift()
            if (window.waitingList.length > 0) PostData(window.waitingList[0], {}, 0)
            if (typeof p.close === "function") p.close(e)
        })
}
