import React from "react"
class DashboardDetail extends React.Component {
    constructor(props) {
        super(props)
        this.state = { ...props }
        this.fn = props.fn
    }
    onReady = (e) => {
        console.log(e)
    }
    componentDidMount() {}
    render() {
        return JSON.stringify(this.state.data)
    }
}
export default DashboardDetail
