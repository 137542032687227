import React from "react"

export class DebtReport extends React.Component {
    constructor(props) {
        super(props)
        this.state = props.state
        this.fn = props.fn
    }
    componentDidMount() {}
    onRefresh = () => {}
    render() {
        return (
            <div className="row">
                <div className="col-md-6">Customer</div>
                <div className="col-md-6">Supplier</div>
            </div>
        )
    }
}
export default DebtReport;