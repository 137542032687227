import React from "react"
import DashboardCell from "./cell"
class DashboardHeader extends React.Component {
    constructor(props) {
        super(props)
        this.fn = props.fn
    }
    componentDidMount() {}
    render() {
        return this.props.header === undefined ? (
            <div className="p-2">
                <span className="fa fa-spin fa-gear mr-1" />
                <span>Please wait ...</span>
            </div>
        ) : (
            <div className="w-100">
                <DashboardCell width={"10rem"} value={this.props.header.id} title={"No. SO"} />
                <DashboardCell width={"7rem"} value={this.props.header.date} title={"Tanggal"} />
                <DashboardCell width={"7rem"} value={this.props.header.shipment_date} title={"Tgl. Kirim"} />
                <br />
                <DashboardCell width={"12rem"} value={this.props.header.name} title={"Nama"} />
                <DashboardCell width={"12rem"} value={this.props.header.phone} title={"Telp"} />
                <br />
                <DashboardCell width={"24rem"} value={this.props.header.address} title={"Alamat"} />
                <br />
            </div>
        )
    }
}
export default DashboardHeader
