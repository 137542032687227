import React from "react"
import DataGrid, { Editing, Column,Selection } from "devextreme-react/data-grid"
//import http from "../../../../network/HTTPClient"
import { PostData } from "../../../Helper";
import CustomStore from "devextreme/data/custom_store";
import axios from "axios";
import { CheckBox } from "devextreme-react";


//const tasks = this.state.datadetail.getTasks();




function getTasks(keyku,data) {
    /*
    return new CustomStore({
      key: "id",
      loadMode: "raw",
      load: () => {
        PostData({url:"open/openpofilter/getfilter",success:(res)=>{
            console.log(res);
           return res['detail'];

        },error:(tes)=>{
            console.log(tes);
        }})
      }
    });
    */
   
    let detailku = { "Access-Control-Allow-Origin": "*", Authorization: "Bearer "+localStorage.getItem(window.token), "data-key": window.token, "data-dir": process.env.REACT_APP_DIR }
    return new CustomStore({
      key: "id",
      loadMode: "raw",
      load: () => {
        return axios.post(window.url + "open/openpofilter/getfilterdetail", {id:keyku},
                    { headers: detailku })
                    .then((response) => response.data);
      },update: function (values) {
        return axios
            .post(window.url + "open/openpofilter/updatedetail", {id: String(keyku),data:values},
            { headers: detailku })
            .then((response) => response)
            .catch(() => {
                throw new Error("Network error")
            })
    }
    });
      /*
        PostData({url:"open/openpofilter/getfilter",data:{fromdate:'2023-02-01',todate:'2023-02-20'},showLoading:true,success:(res)=>{
            //this.setState({loadgridheader:res['header']}) ;
            return res['detail'];
            console.log(res['detail']);
        }})
        */
  }

  


class GroupMasterDetail extends React.Component {
    constructor(props) {
        super(props)
        this.state = { ...props.state}
        console.log("hola1111111");
        this.dataSource = getTasks(props.data.key,props.data.data);
       
    }
    
    cellRender = (data) => {
      if(data.value+"" === "0" || data.value+"" === "null" || data.value === false){
        return <CheckBox defaultValue={false} disabled={true}/>;
      }
      return <CheckBox defaultValue={true} disabled={true}/>;
    }
    
    cellRenderupdate = (cell) => {
      if(cell.value+"" === "0") cell.value = false;
      let onValueChanged = this.onValueChanged.bind(this, cell);
      return <CheckBox defaultValue={cell.value} onValueChanged={onValueChanged}/>;
    }

    onValueChanged(cell, e) {
      cell.setValue(e.value);
    }

    render() {
      const { allMode, checkBoxesMode } = this.state;
        //console.log ("BLABLA");
        console.log("blabla");
       return(
            <DataGrid
                height="100%"
                dataSource={this.dataSource}
                onSaved={this.onSaved}
                onSaving={this.onSaving}
                onInitialized={(e) => this.setState({ grid: e.component })}
                keyExpr={["id","number"]}
                columnAutoWidth={true}
                wordWrapEnabled={true}
                width="100%"
            >

         


                <Editing mode="batch" allowUpdating={true} useIcons={true} />
                <Column dataField="status"  caption="status" dataType="boolean"  cellRender={this.cellRender}
            editCellRender={this.cellRenderupdate}/>
                <Column dataField="id" caption="id" allowEditing={false} visible={true}/>
                <Column dataField="number"  caption="number" allowEditing={false}  visible={true}/>
                <Column dataField="item_id"  caption="item_id" />
                <Column dataField="item_name"  caption="item_name" />
                <Column dataField="qty" caption="qty" />
                <Column dataField="unit_id"  caption="unit_id" />
                <Column dataField="qty_left"  caption="qty_left" />

            </DataGrid>
       )
    }
}
/*
function getTasks(key) {
    return new DataSource({
      store: new ArrayStore({
        data: tasks,
        key: 'ID',
      }),
      filter: ['EmployeeID', '=', key],
    });
  }
*/
export default GroupMasterDetail
